import React from 'react'
import LondonTemplate from '../../../templates/LondonTemplate'

const LondonFaq = () => {
    return (
        <LondonTemplate>
            <div className="salesforce-footer"></div>
            <div className="heroContent">
                <div className="heroSky">
                    <div className="hottieCenter">
                        <img className="herohottie" src="https://developer.salesforce.com/resources2/bootcamps2022/London/Images/hero/2023_BC_Regional-Landing-Page_Hootie.gif" alt="background" />
                    </div>
                    <img className="heroballon" src="https://developer.salesforce.com/resources2/bootcamps2022/London/Images/hero/2023_BC_Regional-Landing-Page_UK-Balloon.gif" alt="background" />
                </div>
            </div>

            <section className="container-content">
                <h1 className="h1Internal">FAQ</h1>
                <h3>Bring on the questions.</h3>
                <h4 className="faqH4">General Info</h4>
                
                <div className="panel-group faq-panel" id="learn-accordion" role="tablist" aria-multiselectable="true">
                    <div className="panel panel-default">
                        <div className="panel-heading" role="tab" id="learn-1">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#learn-accordion" href="#collapse-1" aria-expanded="false" aria-controls="collapse-1">
                                    <span className="glyphicon glyphicon-plus-sign"></span><span className="glyphicon glyphicon glyphicon-minus-sign"></span>
                                    <div className="panel-full">What is Trailblazer Bootcamp?</div>
                                </a>
                            </h4>
                        </div>
                        <div id="collapse-1" className="panel-collapse collapse" role="tabpanel" aria-labelledby="learn-1">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <p>With five days of expert-led learning, nine role-based tracks, two onsite certification exams, and endless networking opportunities, Trailblazer Bootcamp is the place to build expertise and accelerate your path to certification.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="panel panel-default">
                        <div className="panel-heading" role="tab" id="learn-2">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#learn-accordion" href="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
                                    <span className="glyphicon glyphicon-plus-sign"></span><span className="glyphicon glyphicon glyphicon-minus-sign"></span>
                                    <div className="panel-full">Who should come to Trailblazer Bootcamp?</div>
                                </a>
                            </h4>
                        </div>
                        <div id="collapse-2" className="panel-collapse collapse" role="tabpanel" aria-labelledby="learn-2">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <p>Trailblazer Bootcamp offers a variety of role-based tracks including: </p>
                                    <ul>
                                        <li>Administrator</li>
                                        <li>Developer</li>
                                        <li>Architect</li>
                                        <li>Data Rockstar</li>
                                        <li>Marketer</li>
                                    </ul>
                                    <p>We also welcome anyone who wants to explore new areas of interest in Salesforce, MuleSoft, and Tableau.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="panel panel-default">
                        <div className="panel-heading" role="tab" id="learn-3">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#learn-accordion" href="#collapse-3" aria-expanded="false" aria-controls="collapse-3">
                                    <span className="glyphicon glyphicon-plus-sign"></span><span className="glyphicon glyphicon glyphicon-minus-sign"></span>
                                    <div className="panel-full">I’m planning to attend Trailblazer Bootcamp. Which track should I take?</div>
                                </a>
                            </h4>
                        </div>
                        <div id="collapse-3" className="panel-collapse collapse" role="tabpanel" aria-labelledby="learn-3">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <p>Each track aligns with a specific role. Take a look at the detailed descriptions of our role-based tracks to see which suits you best. If you have questions, you can post them in the <a href="https://trailhead.salesforce.com/trailblazer-community/groups/0F94S000000kKjeSAE?sort=LAST_MODIFIED_DATE_DESC&tab=" target="_blank">Trailblazer Bootcamp London Community Group</a>.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="panel panel-default">
                        <div className="panel-heading" role="tab" id="learn-4">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#learn-accordion" href="#collapse-4" aria-expanded="false" aria-controls="collapse-4">
                                    <span className="glyphicon glyphicon-plus-sign"></span><span className="glyphicon glyphicon glyphicon-minus-sign"></span>
                                    <div className="panel-full">Is there prework for Trailblazer Bootcamp?</div>
                                </a>
                            </h4>
                        </div>
                        <div id="collapse-4" className="panel-collapse collapse" role="tabpanel" aria-labelledby="learn-4">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <p>Yes. To ensure the best learning experience possible, we’ve identified some prework for you to complete before you arrive at Trailblazer Bootcamp. Navigate to your track <a href="https://trailhead.salesforce.com/help?article=Trailblazer-Bootcamp-Prework" target="_blank">here</a> and click on the prework link for your track. BONUS: You’ll earn the exclusive Trailblazer Bootcamp London Community Badge when you complete your prework.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="panel panel-default">
                        <div className="panel-heading" role="tab" id="learn-5">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#learn-accordion" href="#collapse-5" aria-expanded="false" aria-controls="collapse-5">
                                    <span className="glyphicon glyphicon-plus-sign"></span><span className="glyphicon glyphicon glyphicon-minus-sign"></span>
                                    <div className="panel-full">Will computers be provided at Trailblazer Bootcamp?</div>
                                </a>
                            </h4>
                        </div>
                        <div id="collapse-5" className="panel-collapse collapse" role="tabpanel" aria-labelledby="learn-5">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <p>No. Computers are not provided at Trailblazer Bootcamp, so attendees will need to bring their own device. </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="panel panel-default">
                        <div className="panel-heading" role="tab" id="learn-6">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#learn-accordion" href="#collapse-6" aria-expanded="false" aria-controls="collapse-6">
                                    <span className="glyphicon glyphicon-plus-sign"></span><span className="glyphicon glyphicon glyphicon-minus-sign"></span>
                                    <div className="panel-full">Do I need to configure anything on my computer to get it set up for Trailblazer Bootcamp? </div>
                                </a>
                            </h4>
                        </div>
                        <div id="collapse-6" className="panel-collapse collapse" role="tabpanel" aria-labelledby="learn-6">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <p>Yes. Taking an exam on your personal computer requires some setup. Follow the steps <a href="https://trailhead.salesforce.com/help?article=Guide-to-Trailblazer-Bootcamp" target="_blank">here</a> to get your computer exam-ready. </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="panel panel-default">
                        <div className="panel-heading" role="tab" id="learn-7">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#learn-accordion" href="#collapse-7" aria-expanded="false" aria-controls="collapse-7">
                                    <span className="glyphicon glyphicon-plus-sign"></span><span className="glyphicon glyphicon glyphicon-minus-sign"></span>
                                    <div className="panel-full">Do I have to take both exams during Trailblazer Bootcamp?</div>
                                </a>
                            </h4>
                        </div>
                        <div id="collapse-7" className="panel-collapse collapse" role="tabpanel" aria-labelledby="learn-7">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <p>Exams are optional. You may choose to receive a voucher and take your exam(s) after bootcamp. Vouchers will be sent via email post-event, and exam(s) must be taken before 31 August 2023, at 0859 BST.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="panel panel-default">
                        <div className="panel-heading" role="tab" id="learn-8">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#learn-accordion" href="#collapse-8" aria-expanded="false" aria-controls="collapse-8">
                                    <span className="glyphicon glyphicon-plus-sign"></span><span className="glyphicon glyphicon glyphicon-minus-sign"></span>
                                    <div className="panel-full">Is there a Community Group for Trailblazer Bootcamp London?</div>
                                </a>
                            </h4>
                        </div>
                        <div id="collapse-8" className="panel-collapse collapse" role="tabpanel" aria-labelledby="learn-8">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <p>
                                    Yes. Get updates and post questions in the <a target="_blank" href="https://trailhead.salesforce.com/trailblazer-community/groups/0F94S000000kKjeSAE?sort=LAST_MODIFIED_DATE_DESC&tab=">Trailblazer Bootcamp London Community Group</a>.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <h4 className="faqH4">Registration</h4>

                <div className="panel-group faq-panel" id="learn-accordion" role="tablist" aria-multiselectable="true">
                    <div className="panel panel-default">
                        <div className="panel-heading" role="tab" id="reg-1">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#reg-accordion2" href="#collapse-r1" aria-expanded="false" aria-controls="collapse-r1">
                                    <span className="glyphicon glyphicon-plus-sign"></span><span className="glyphicon glyphicon glyphicon-minus-sign"></span>
                                    <div className="panel-full">What is the cost of the Trailblazer Bootcamp pass?</div>
                                </a>
                            </h4>
                        </div>
                        <div id="collapse-r1" className="panel-collapse collapse" role="tabpanel" aria-labelledby="reg-1">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <p>Purchase a full Trailblazer Bootcamp pass at early bird pricing of ₤2,599 until 0759 GMT on 03 March 2023. Thereafter, the full Trailblazer Bootcamp pass cost is ₤2,999. Accommodations are not included in the price of the Trailblazer Bootcamp pass and may be purchased separately through the registration process.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="panel panel-default">
                        <div className="panel-heading" role="tab" id="reg-2">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#reg-accordion2" href="#collapse-r2" aria-expanded="false" aria-controls="collapse-r2">
                                    <span className="glyphicon glyphicon-plus-sign"></span><span className="glyphicon glyphicon glyphicon-minus-sign"></span>
                                    <div className="panel-full">Are hotel blocks available for the event?</div>
                                </a>
                            </h4>
                        </div>
                        <div id="collapse-r2" className="panel-collapse collapse" role="tabpanel" aria-labelledby="reg-2">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <p>Yes. Salesforce has reserved hotel blocks at the <a target="_blank" href="https://www.parkplazariverbank.com/">Park Plaza London Riverbank</a>. This limited block of rooms is available at a pre-negotiated rate while supplies last. You’ll have the opportunity to book your room at the end of your registration process.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="panel panel-default">
                        <div className="panel-heading" role="tab" id="reg-3">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#reg-accordion2" href="#collapse-r3" aria-expanded="false" aria-controls="collapse-r3">
                                    <span className="glyphicon glyphicon-plus-sign"></span><span className="glyphicon glyphicon glyphicon-minus-sign"></span>
                                    <div className="panel-full">How do I request a visa invitation?</div>
                                </a>
                            </h4>
                        </div>
                        <div id="collapse-r3" className="panel-collapse collapse" role="tabpanel" aria-labelledby="reg-3">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <p>You can request a visa invitation letter during the registration process. Please note that you must complete your registration to secure your visa invitation. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <div className="panel panel-default">
                        <div className="panel-heading" role="tab" id="reg-4">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#reg-accordion2" href="#collapse-r4" aria-expanded="false" aria-controls="collapse-r4">
                                    <span className="glyphicon glyphicon-plus-sign"></span><span className="glyphicon glyphicon glyphicon-minus-sign"></span>
                                    <div className="panel-full">What is the dress code?</div>
                                </a>
                            </h4>
                        </div>
                        <div id="collapse-r4" className="panel-collapse collapse" role="tabpanel" aria-labelledby="reg-4">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <p>Trailblazer Bootcamp attire is business casual. Pack layers and check the weather before you arrive.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <div className="panel panel-default">
                        <div className="panel-heading" role="tab" id="reg-5">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#reg-accordion2" href="#collapse-r5" aria-expanded="false" aria-controls="collapse-r5">
                                    <span className="glyphicon glyphicon-plus-sign"></span><span className="glyphicon glyphicon glyphicon-minus-sign"></span>
                                    <div className="panel-full">What is the cancellation policy?</div>
                                </a>
                            </h4>
                        </div>
                        <div id="collapse-r5" className="panel-collapse collapse" role="tabpanel" aria-labelledby="reg-5">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <p>Cancellation Policy:</p>
                                    <ul>
                                        <li>Cancellation requests must be received in writing.</li>
                                        <li>Cancellations are <b>final</b>.</li>
                                        <li >Cancellation/refund deadlines apply:
                                            <ul style={{paddingTop: '10px'}}>
                                                <li>Full refund: Cancellation must be received by 08:59 BST on 08-April-2023. </li>
                                                <li>50% refund: Cancellation must be received before 08:59 BST on 22-April-2023.</li>
                                                <li>No refunds will be given after 09:00 BST on 23-April-2023.</li>
                                                <li>Failure to check in at the event does not constitute cancellation and will not give rise to a refund or a credit.</li>
                                                <li><a target="_blank" href="https://trailblazer.salesforce.com/Ev_SiteTOS">Event Terms of Service</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
            
            
                    <div className="panel panel-default">
                        <div className="panel-heading" role="tab" id="reg-6">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#reg-accordion2" href="#collapse-r6" aria-expanded="false" aria-controls="collapse-r6">
                                    <span className="glyphicon glyphicon-plus-sign"></span><span className="glyphicon glyphicon glyphicon-minus-sign"></span>
                                    <div className="panel-full">Who can I contact for assistance with registering for Trailblazer Bootcamp?</div>
                                </a>
                            </h4>
                        </div>
                        <div id="collapse-r6" className="panel-collapse collapse" role="tabpanel" aria-labelledby="reg-6">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <p>Please email the Trailblazer Bootcamp Registration team at <a href="mailto:bootcamp-lhr@jomablue.com">bootcamp-lhr@jomablue.com</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
            
                    <div className="panel panel-default">
                        <div className="panel-heading" role="tab" id="reg-7">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#reg-accordion2" href="#collapse-r7" aria-expanded="false" aria-controls="collapse-r7">
                                    <span className="glyphicon glyphicon-plus-sign"></span><span className="glyphicon glyphicon glyphicon-minus-sign"></span>
                                    <div className="panel-full">Does Trailblazer Bootcamp have a Code of Conduct?</div>
                                </a>
                            </h4>
                        </div>
                        <div id="collapse-r7" className="panel-collapse collapse" role="tabpanel" aria-labelledby="reg-7">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <p>Yes, check out our <a target="_blank" href="https://success.salesforce.com/ev_sitecoc">Code of Conduct</a>. Have a safe and fun conference!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container-content">
                <div className="center">
                    <img className="logo logoInternal" src="https://developer.salesforce.com/resources2/bootcamps2022/London/Images/home/2023-Bootcamp-Seal_London_Color.png" />
                </div>
            </section>

            <div className="characters schedule-bg">
                <img src="https://developer.salesforce.com/resources2/bootcamps2022/London/Images/faq/FAQ.png" />
            </div>
        </LondonTemplate>
    )
}

export default LondonFaq
